import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import lottie_person from '../media/lottie_60875_confuse_person.json';
import Carousel from '../component/Carousel'
import './HomeTeam.css';
import i18next from 'i18next';

class HomeTeam extends React.Component {

    constructor(props) {
        super(props);
        this.handleCareerClick = this.handleCareerClick.bind(this);
    }

    isWinter() {
        let month = (new Date()).getMonth() + 1; // january = 0
        if (month === 1 || month === 2 || month === 12) {
            return true;
        }
        return false;
    }

    handleCareerClick() {
        this.props.navigate('/career');
    }

    getHighlightPlaceholder(i, teamSrc) {
        let placeholderHeader = teamSrc[i].header;
        const academicDegrees = ['Mag. ', 'Dipl.-Ing. Mag. ', 'Dipl.-Ing. MSc. ', 'Dipl. Wirtsch-Ing. '];
        for (let i = 0; i < academicDegrees.length; i++) {
            let academicDegree = academicDegrees[i];
            if (placeholderHeader.startsWith(academicDegree) === true) {
                placeholderHeader = (
                      <span>{academicDegree}<br />{placeholderHeader.substring(academicDegree.length)}</span>
                );
                break;
            }
        }
        let placeholderText = teamSrc[i].text;
        let placeholderItem = (
            <div key={`gallery-placeholder-${i}`} className="App-home-team-gallery-item App-home-team-gallery-placeholder">
                <img src="/images/team/Platzhalter.webp" alt="Platzhalter" />
                <div className="App-home-team-gallery-placeholder-inner">
                    <div className="App-home-team-gallery-placeholder-header">{placeholderHeader}</div>
                    <div className="App-home-team-gallery-placeholder-text">{placeholderText}</div>
                </div>
            </div>
        );
        return placeholderItem;
    }

    getCareerPersonPlaceholder(i) {
        let placeholderItem = (
            <div key={`gallery-placeholder-${i}`} className="App-home-team-gallery-item App-home-team-gallery-placeholder clickable" onClick={this.handleCareerClick}>
                <img src="/images/team/Platzhalter.webp" alt="Platzhalter" />
                <div className="App-home-team-gallery-placeholder-inner">
                    <div className="App-home-team-gallery-placeholder-lottie">
                        <Player autoplay loop src={lottie_person} />
                    </div>
                </div>
            </div>
        );
        return placeholderItem;
    }

    getFillTextPlaceholder(i, text) {
        let placeholderItem = (
            <div key={`gallery-placeholder-${i}`} className="App-home-team-gallery-item App-home-team-gallery-placeholder clickable" onClick={this.handleCareerClick}>
                <img src="/images/team/Platzhalter.webp" alt="Platzhalter" />
                <div className="App-home-team-gallery-placeholder-inner">
                    <div className="App-home-team-gallery-placeholder-filltext">
                         <h4>{text}</h4>
                    </div>
                </div>
            </div>
        );
        return placeholderItem;
    }

    render() {
        const teamSrc = [            
            { 'path': '/images/team/BernhardSchleicher.webp', 'header': 'Ing. Bernhard Schleicher', 'text': i18next.t('HomeTeam.Jobs.ManagingDirector') },
            { 'path': '/images/team/GregorStritzinger.webp', 'header': 'Mag. Gregor Stritzinger', 'text': i18next.t('HomeTeam.Jobs.ManagingDirector') },            
            { 'path': this.isWinter() ? '/images/team/JonasRoethlin2.webp' : '/images/team/JonasRoethlin1.webp', 'header': 'Jonas Röthlin', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },
            { 'path': '/images/team/MarkusStritzinger.webp', 'header': 'Markus Stritzinger', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },            
            { 'path': '/images/team/FelixDoppelbauer.webp', 'header': 'Ing. Felix Doppelbauer', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },
            { 'path': '/images/team/MarkusBrindl.webp', 'header': 'Markus Brindl', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },   
            { 'path': '/images/team/MarkusKaufmann.webp', 'header': 'Markus Kaufmann', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },           
            { 'path': '/images/team/MaximilianGrabner.webp', 'header': 'Maximilian Grabner', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },
            { 'path': '/images/team/WolfgangKratky.webp', 'header': 'Ing. Wolfgang Kraktky', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineeringSales') },
            { 'path': '/images/team/ChristianHoeller.webp', 'header': 'Christian Höller', 'text': i18next.t('HomeTeam.Jobs.ITExpert') },
            { 'path': '/images/team/EricBuchinger.webp', 'header': 'Eric Buchinger', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },
            { 'path': '/images/team/XaverButtinger.webp', 'header': 'Xaver Buttinger', 'text': i18next.t('HomeTeam.Jobs.SoftwareEngineering') },
            { 'path': '/images/team/DanielSchobesberger.webp', 'header': 'Daniel Schobesberger', 'text': i18next.t('HomeTeam.Jobs.Trainee') },          
            { 'path': '/images/team/KarinWeinzierl.webp', 'header': 'Karin Weinzierl', 'text': i18next.t('HomeTeam.Jobs.Accounting') },
            { 'path': '/images/team/SylviaMartono.webp', 'header': 'Sylvia Fellhofer', 'text': i18next.t('HomeTeam.Jobs.InnovationAssistant') },
            { 'path': '/images/team/SusanneEbnerMayer.webp', 'header': 'Mag. Susanne Ebner-Mayer', 'text': i18next.t('HomeTeam.Jobs.Consultant') } /* , 'style': 'highlight' */
        ];
        let galleryRows = [];
        let galleryItems = [];
        for (let i = 0; i < teamSrc.length; i++) {
            let highlight = teamSrc[i].style === 'highlight';
            let galleryItem = (
                <div key={`gallery-item-${i}`} className={`App-home-team-gallery-item App-home-team-gallery-item-${i}${highlight ? ' highlight' : ''}`}>
                    <img src={teamSrc[i].path} alt={teamSrc[i].header} />
                    <div className="App-home-team-gallery-item-inner">
                        <div className="App-home-team-gallery-item-header">{teamSrc[i].header}</div>
                        <div className="App-home-team-gallery-item-text">{teamSrc[i].text}</div>
                    </div>
                </div>
            );
            galleryItems.push(galleryItem);

            /* highlight placeholder */
            if (highlight === true) {
                let placeholderItem = this.getHighlightPlaceholder(i, teamSrc);
                galleryItems.push(placeholderItem);
            }
            
            /* career person placeholder */           
            if (i === 15) {
                let placeholderItem = this.getCareerPersonPlaceholder(i);
                galleryItems.push(placeholderItem);
            }

            /* filltext placeholder */
            /*
            if (i === 1) {
                let placeholderItem = this.getFillTextPlaceholder(i, i18next.t('HomeTeam.Placeholder.Team'));
                galleryItems.push(placeholderItem);
            }
            */

            /* filltext placeholder */
            /*
            if (i === 3) {
                let placeholderItem = this.getFillTextPlaceholder(i, i18next.t('HomeTeam.Placeholder.Think'));
                galleryItems.push(placeholderItem);
            }
            */
            
            /* filltext placeholder */
            /*
            if (i === 6) {
                let placeholderItem = this.getFillTextPlaceholder(i, i18next.t('HomeTeam.Placeholder.Idea'));
                galleryItems.push(placeholderItem);
            }
            */
            
            /* filltext placeholder */
            if (i === 9) {
                let placeholderItem = this.getFillTextPlaceholder(i, i18next.t('HomeTeam.Placeholder.Creative'));
                galleryItems.push(placeholderItem);
            }

            /* filltext placeholder */
            /*
            if (i === 12) {
                let placeholderItem = this.getFillTextPlaceholder(i, i18next.t('HomeTeam.Placeholder.Laugh'));
                galleryItems.push(placeholderItem);
            }
            */

            /* filltext placeholder */
            /*
            if (i === 12) {
                let placeholderItem = this.getFillTextPlaceholder(i, i18next.t('HomeTeam.Placeholder.Best'));
                galleryItems.push(placeholderItem);
            }
            */
            
            if (galleryItems.length === 6 || (i + 1) === teamSrc.length) {
                let galleryRow = (
                    <div key={`gallery-row-${i}`} className={`App-home-team-gallery-row App-home-team-gallery-row-${galleryRows.length}`}>
                        {galleryItems}
                    </div>
                );
                galleryRows.push(galleryRow);
                galleryItems = [];
            }
        }
        return (
            <div className="App-home-team">
                <Carousel className="App-home-team-carousel" aspectRatio={1.50094} src={teamSrc} hideIndicator={true} hidePreview={false} previewRows={4} previewWidth={100} />
                <div className="App-home-team-gallery escape-left escape-right">
                    {galleryRows}
                </div>
            </div>
        );
    }
}

export default function HomeTeamUseNavigate(props) {
    const navigate = useNavigate();
    return <HomeTeam {...props} navigate={navigate} />
}